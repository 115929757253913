<template>
  <AppLayout>
    <router-view/>
  </AppLayout>
</template>

<script>
</script>

<style>
*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
*::-webkit-scrollbar-track {
    background-color: lightgray;
}
*::-webkit-scrollbar-thumb {
    background-color: #000000;
}
</style>
